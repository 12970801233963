$color: #e2161a;
// General
.landing {
  color: #283153 !important;
  background-color: #FFFFFF;
}

.title {
  font-weight: 700 !important;
  font-size: 45px !important;
}

.subtitle {
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 44px;
  margin: 0;
}

.description {
  font-weight: 400 !important;
  font-size: 25px !important;
  line-height: 38px;
  margin: 0;
}

.text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 40px !important;
  margin: 0 !important;
}

.hfull {
  min-height: calc(100vh - 200px);
}

// Hero
.hero {
  width: 100%;
  min-height: calc(100vh - 180px);

  &__content {
    position: relative;
    z-index: 1000;
  }

  &__overlay {
    position: absolute;
    z-index: 100;
    min-height: calc(100vh - 180px);
    width: 100%;
    background-color: black;
    opacity: .4;
  }

  &__image {
    position: absolute;
    height: calc(100vh - 180px);
    width: 100%;
    object-fit: cover;
    object-position: top;
    filter: blur(5px);
    -webkit-filter: blur(8px);
  }
}

.bannerPhone {
  width: 600px;
  margin-bottom: 20px;
}

.qr {
  width: 150px;
}

.download {
  width: 100%;
  min-height: calc(100vh - 180px);

  &__content {
    position: relative;
    z-index: 1000;
  }

  &__image {
    position: absolute;
    height: 200vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.imgDownload {
  height: 200px;
}

.imgPhoneDownload {
  height: 700px;
  margin-bottom: 40px;
}

.divider {
  border-left: 150px solid #FF6565;
  height: 2px;
  margin: 20px 0;
}

.dividerVertical {
  border-left: 2px solid #FFFFFF;
  height: 150px;
  margin: 0 30px;
}

.store {
  cursor: pointer;
  width: 120px;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 35px !important;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 15px !important;
  }

  // Hero
  .hero {
    min-height: 100vh;

    &__overlay {
      min-height: 100vh;
    }

    &__image {
      height: 100vh;
    }
  }

  .download {
    min-height: 100vh !important;

    &__image {
      height: 200vh !important;
    }
  }

  .bannerPhone {
    width: 550px !important;
  }

  .qr {
    width: 120px;
  }

  .imgPhoneDownload {
    height: 600px;
  }

  .store {
    width: 100px;
  }
}

@media screen and (max-width: 810px) {

  // Hero
  .hero {
    min-height: 70vh;

    &__overlay {
      min-height: 70vh;
    }

    &__image {
      height: 70vh;
    }
  }

  .download {
    min-height: 100vh !important;

    &__image {
      height: 200vh !important;
    }
  }

  .imgPhoneDownload {
    height: 550px;
  }

  .bannerPhone {
    width: 550px !important;
  }

  .qr {
    width: 150px;
  }

  .store {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 40px !important;
  }

  .subtitle {
    font-size: 20px !important;
    line-height: 30px;
  }

  // Hero
  .hero {
    min-height: 100vh !important;

    &__overlay {
      min-height: 100vh !important;
    }

    &__image {
      height: 100vh !important;
    }
  }

  .download {
    background-color: rgba(254, 100, 101, .5);
    min-height: 100vh !important;

    &__image {
      display: none;
    }
  }

  .imgPhoneDownload {
    height: 650px;
  }

  .bannerPhone {
    width: 400px !important;
  }

  .qr {
    width: 150px;
  }
}

@media screen and (max-width: 575px) {
  .dividerVertical {
    border-left: 150px solid #FFFFFF;
    height: 2px;
    margin: 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 35px !important;
  }

  .subtitle {
    font-size: 20px !important;
    line-height: 30px;
  }

  .text {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .description {
    font-size: 20px !important;
    line-height: 30px;
  }

  // Hero
  .hero {
    min-height: 100vh !important;

    &__overlay {
      min-height: 100vh !important;
    }

    &__image {
      height: 100vh !important;
    }
  }

  .download {
    background-color: rgba(254, 100, 101, .5);
    min-height: 100vh !important;

    &__image {
      display: none;
    }
  }

  .imgPhoneDownload {
    height: 550px;
  }

  .bannerPhone {
    width: 350px !important;
    margin-bottom: 20px;
  }

  .qr {
    width: 120px;
  }
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 25px !important;
  }

  .subtitle {
    font-size: 18px !important;
    line-height: 30px;
  }

  .text {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .description {
    font-size: 20px !important;
    line-height: 30px;
  }

  // Hero
  .hero {
    min-height: 100vh !important;

    &__overlay {
      min-height: 100vh !important;
    }

    &__image {
      height: 100vh !important;
    }
  }

  .download {
    background-color: rgba(254, 100, 101, .5);
    min-height: 100vh !important;

    &__image {
      display: none;
    }
  }

  .imgPhoneDownload {
    height: 450px;
  }

  .bannerPhone {
    width: 350px !important;
    margin-bottom: 20px;
  }

  .qr {
    width: 80px;
  }
}